
.add-button {
  background-color: #0d9cec;
  border-radius: 10px;
  margin-left: 15px;
  color: white;
  float: right
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.data{
  font-weight: bold;
}

.btn-save{
  float: right;
  min-width: 100px !important;
  margin-top: 20px!important;
}

.location{
  border: 1px solid #4093df;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.input-error {
  border: red 1px solid;
}

.dialog-create{
  height: 95svh;
  overflow: auto;
}
.add-group-button {
  background-color: #3366fe;
  color: white;
  border-radius: 10px;
  padding: 3px 6px;
  float: right;
  cursor: pointer;
}
